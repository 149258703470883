<style lang="scss">
@import "~@/assets/css/var";
.compare-table {
  * {
    box-sizing: border-box;
  }
  .panel {
    position: absolute;
    top: 0;
    left: 140px;
    right: 0;
    bottom: 0;
    overflow: overlay;
    &::-webkit-scrollbar {
      position: absolute;
      top: 0;
    }
  }
  .panel-left {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 140px;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    &.scroll {
      box-shadow: 8px 0 5px -4px rgba(0, 0, 0, .12);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .compare-head {
      display: flex;
      border-bottom: 1px solid $border;
    }
    .compare-unit-left {
      width: 140px;
      font-size: 14px;
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }
  .my-input {
    .el-input__inner {
      border: 0;
      padding: 0 10px;
      text-align: center;
    }
  }

  .up {
    color: $red;
    font-weight: bold;
  }
  .down {
    color: $green;
    font-weight: bold;
  }

  .empty {
    padding-top: 200px;
    font-size: 20px;
    color: #999;
    text-align: center;
  }

  .compare-head {
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: $mainLight;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .compare-unit {
      border-color: #fff;
      display: flex;
      align-items: center;
    }
    .el-icon-close {
      cursor: pointer;
      color: #fff;
      margin-left: 5px;
      &:hover {
        color: $red;
      }
    }
  }
  .compare-body {
    display: grid;
    //border-right: 1px solid $border;
  }
  .compare-item {
    display: flex;
    align-items: stretch;
    font-size: 0;
    white-space: nowrap;
    border-left: 1px solid $border;
    border-bottom: 1px solid $border;

    &.compare-block {
      background: $border;
    }
  }
  .compare-unit {
    position: relative;
    display: inline-block;
    width: 300px;
    flex-shrink: 0;
    font-size: 12px;
    padding: 6px 6px;
    border-right: 1px solid $border;
    word-break: break-all;
    white-space: normal;
    vertical-align: middle;
    min-height: 40px;

    .el-icon-date {
      position: absolute;
      color: $main;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .compare-block {
    color: $main;
    font-weight: bold;
    font-size: 14px;
    border-color: transparent;
  }
}
.dialog-canvas {
  height: 400px;
}
</style>

<template>
    <div class="compare-table">
        <div ref="left" class="panel-left" :class="{scroll: isSrollRight}" @scroll="panelLeftScroll" >
            <div class="compare-item compare-head">
                <div class="compare-unit" style="font-size: 14px;">药品参数</div>
                <div class="compare-unit" style="justify-content: center;font-size: 14px;" v-for="item in list" :key="item.comCode">
                    {{ item.comName }} {{item.spec}} {{ item.compName }}
                    <i class="el-icon-loading" v-if="item.loading"></i>
                    <i class="el-icon-close" @click="$emit('del', index)" v-if="item.comCode"></i>
                </div>
                <div class="compare-unit">
                    <slot name="drug"></slot>
                </div>
            </div>
            <div class="compare-body">
                <div class="compare-item" :class="{'compare-block': col.block}" v-for="col in cols" :key="col.key">
                    <div class="compare-unit compare-unit-left" :class="{'compare-block': col.block}" v-html="col.value"></div>
                    <div class="compare-unit" :class="{'compare-block': col.block}" v-for="item in list" :key="item.id">
                        <slot :name="'slot_' + col.key" :row="item" :col="col">
                          <template v-if="col.block">&nbsp;</template>
                            <div v-else  style="display: inline-block" v-html="(item.real[col.key] || (col.block ? '' : '--')) + (col.suffix || '')"></div>
                          <!-- &nbsp; -->
                        </slot>
                    </div>
                    <div class="compare-unit">
                        <slot v-if="['spec', 'corp'].includes(col.key)" :name="'slot_' + col.key" :row="{real: {}}" :col="col"></slot>
                    </div>
                </div>
            </div>
        </div>

        <div ref="panel" class="panel" @scroll="panelScroll">
            <div ref="head" class="compare-item compare-head">
                <div class="compare-unit" style="justify-content: center;font-size: 14px;" v-for="(item, index) in list" :key="item.comCode">
                    {{ item.comName }} {{ item.spec }} {{ item.compName }}
                    <i class="el-icon-loading" v-if="item.loading"></i>
                    <i class="el-icon-close" @click="$emit('del', index)" v-if="item.comCode"></i>
                </div>
                <div class="compare-unit">
                    <slot name="drug"></slot>
                </div>
            </div>
            <div class="compare-body">
                <div class="compare-item" :class="{'compare-block': col.block}" v-for="col in cols" :key="col.key">
                    <div class="compare-unit" :class="{'compare-block': col.block}" v-for="item in list" :key="item.id"
                    :style="{textAlign: col.listClass === 'tc'? 'center' : 'left'}">
                        <slot :name="'slot_' + col.key" :row="item" :col="col">
                            <el-link v-if="col.event && item.real[col.key]" :underline="false"
                                     @click="col.event(item)" type="primary">{{ item.real[col.key] || '--'}}</el-link>
                            <template v-else-if="col.block">
                              &nbsp;
                            </template>
                            <template v-else>
                              <div  style="display: inline-block" v-html="(item.real[col.key] || (col.block ? '' : '--') )+ (col.suffix || '')"></div>
                            </template>

                            <i v-if="col.calc" class="el-icon-date clickable" @click="$store.commit('calcShow', true)"></i>
                        </slot>
                    </div>
                    <div class="compare-unit" :class="{'compare-block': col.block}">
                        <slot v-if="['spec', 'corp'].includes(col.key)" :name="'slot_' + col.key" :col="col"></slot>
                        <template v-else>&nbsp;</template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 是否滚动到右边
            isSrollRight: false,
            update:false,
        }
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        cols: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        panelScroll(e) {
            this.$refs.head.scrollLeft = e.target.scrollLeft
            this.$refs.left.scrollTop = e.target.scrollTop
            this.isSrollRight = e.target.scrollLeft > 2
        },
        panelLeftScroll(e) {
            this.$refs.panel.scrollTop = e.target.scrollTop
        },
    }
}
</script>